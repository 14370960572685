/* common::extends
--------------------------------------------------------------------------------------------------------------------- */
.related-links-top {
  margin: 0 0 50px;

  ul {
    display: flex;
    gap: 10px;
    overflow: auto hidden;
    white-space: nowrap;
    padding-bottom: 10px;

    &::-webkit-scrollbar {
      height: 6px;
      border-radius: 12px;
      overflow: hidden;
    }

    &::-webkit-scrollbar-track {
      background: #f5f5f5;
      border-radius: 12px;
    }

    &::-webkit-scrollbar-thumb {
      background: #00000020;
      border-radius: 12px;
      transition: all 0.325s ease-in-out;
      cursor: pointer;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #c7a02b;
    }

    li {
      display: inline-flex;
      width: auto;
      white-space: inherit;

      a {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        border: 1px solid #00000020;
        padding: 8px 16px;
        text-transform: uppercase;
        transition: all 0.325s ease-in-out;

        &:hover {
          background-color: #c7a02b;
          border-color: #c7a02b;
          color: white;
        }
      }
    }
  }
}

.related-links-bottom {
  margin: 100px 0 50px;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0 0 30px;
  }

  ul {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;

    @include responsive-down(tablet-extra-large){
      grid-template-columns: repeat(3, 1fr);
    }

    @include responsive-down(mobile-extra-large){
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

.seo-description {
  margin: 100px 0 50px;
  columns: 2;
  gap: 50px;

  @include responsive-down(tablet-extra-large){
    columns: 1;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.4 !important;
  }

  p {
    & + h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-top: 30px;
    }
  }
}

.blog-description img {
  width: 100%;
  height: auto;
}
